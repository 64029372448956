import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import Heading from "../typography/Heading";
import { withMargin } from "../../../theme/spacing";
import { linkResolver } from "../../../utils/linkResolver";

const Content = styled.section`
  p {
    margin-bottom: ${props => props.theme.spacing.xl};
  }

  ul {
    padding-left: 20px;
  }

  li {
    list-style: disc;
    padding-left: ${props => props.theme.spacing.m};
    margin-bottom: ${props => props.theme.spacing.m};

    &:last-of-type {
      margin-bottom: ${props => props.theme.spacing.xl};
    }
  }

  h3 {
    margin-bottom: ${props => props.theme.spacing.l};
  }

  h4 {
    margin-bottom: ${props => props.theme.spacing.m};
  }
`;

const Date = styled.h6`
  color: ${props => props.theme.colors.grey02};
  ${props => withMargin(props)}
`;

const RichTextBlock = ({ heading, date, body }) => (
  <>
    {heading && <Heading margin="0 0 xxl">{heading}</Heading>}
    {date && <Date margin="0 0 xxl">{date}</Date>}
    <Content>
      <RichText render={body} linkResolver={linkResolver} />
    </Content>
  </>
);

RichTextBlock.propTypes = {
  heading: PropTypes.string,
  date: PropTypes.string,
  body: PropTypes.array.isRequired,
};

export default RichTextBlock;
