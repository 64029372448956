import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Layout from "../components/new/Layout";
import Spacer from "../components/new/Spacer";
import RichTextBlock from "../components/new/blocks/RichTextBlock";

const TermsOfUsePage = ({ data }) => {
  const content = data.prismic.allTerms_of_uses.edges[0].node;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`TermsOfUse`}
        titleTemplate={`%s | IPG - DXTRA`}
        meta={[
          {
            name: `description`,
            content: meta.description,
          },
          {
            property: `og:title`,
            content: `TermsOfUse | IPG - DXTRA`,
          },
          {
            property: `og:description`,
            content: meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: meta.description,
          },
        ].concat(meta)}
      />
      <Layout navigationTransparent={false}>
        <Grid>
          <Row>
            <Col xs={12} mdOffset={1} md={10} lgOffset={2} lg={8}>
              <Spacer padding={{ xs: "80px 0 40px", m: "180px 0 90px" }}>
                <RichTextBlock
                  heading={content.page_title}
                  date={content.date}
                  body={content.body_copy}
                />
              </Spacer>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </>
  );
};

TermsOfUsePage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      allTerms_of_uses {
        edges {
          node {
            page_title
            date
            body_copy
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const TermsOfUseData = () => (
  <StaticQuery
    query={`${query}`}
    render={data => <TermsOfUsePage data={data} />}
  />
);

export default TermsOfUseData;
