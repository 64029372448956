import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { withMargin } from "../../../theme/spacing";
import slashSvg from "../../../images/icons/slash.svg";

const Container = styled.h1`
  transition: color 100ms ease-in out;
  text-align: ${(props) => props.align};
  color: ${(props) =>
    props.light ? props.theme.colors.white : props.theme.colors.primary};
  ${(props) =>
    props.semiBold &&
    css`
      font-weight: ${props.theme.fontWeights.semiBold};
    `}

  ${(props) =>
    props.grey &&
    css`
      color: ${props.theme.colors.grey03};
    `}

  ${(props) =>
    props.muted &&
    css`
      color: ${props.theme.colors.grey02};
    `}

  ${(props) =>
    props.slashBefore &&
    css`
      text-align: center;
      p {
        text-align: center;
      }
      @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
        margin-left: 30px;
        position: relative;
        text-align: left;

        p {
          text-align: left;
        }

        &::before {
          content: url(${slashSvg});
          position: absolute;
          top: -15px;
          left: -30px;
          transform: rotate(-5deg);
        }
      }
    `}

  ${(props) =>
    props.slashInline &&
    css`
      &::before {
        top: 0px;
      }
    `}

  ${(props) => {
    switch (props.size) {
      case "xxl":
        return css`
          font-size: ${props.theme.fontSizes.mobile.xxxl}px;
          line-height: ${props.theme.lineHeights.mobile.xxxl}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.xxxl}px;
            line-height: ${props.theme.lineHeights.desktop.xxxl}px;
          }
        `;
      case "xl":
        return css`
          font-size: ${props.theme.fontSizes.mobile.xxl}px;
          line-height: ${props.theme.lineHeights.mobile.xxxl}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.xxl}px;
            line-height: ${props.theme.lineHeights.desktop.xxxl}px;
          }
        `;
      case "l":
        return css`
          font-size: ${props.theme.fontSizes.mobile.xl}px;
          line-height: ${props.theme.lineHeights.mobile.xxl}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.xl}px;
            line-height: ${props.theme.lineHeights.desktop.xxl}px;
          }
        `;
      case "m":
        return css`
          font-size: ${props.theme.fontSizes.mobile.l}px;
          line-height: ${props.theme.lineHeights.mobile.l}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.l}px;
            line-height: ${props.theme.lineHeights.desktop.l}px;
          }
        `;
      case "ms":
        return css`
          font-size: ${props.theme.fontSizes.mobile.ms}px;
          line-height: ${props.theme.lineHeights.mobile.ms}px;
          text-align: center;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.ms}px;
            line-height: ${props.theme.lineHeights.desktop.ms}px;
            width: 210px;
            text-align: left;
          }
        `;
      case "s":
        return css`
          font-size: ${props.theme.fontSizes.mobile.ms}px;
          line-height: ${props.theme.lineHeights.mobile.ms}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.m}px;
            line-height: ${props.theme.lineHeights.desktop.m}px;
          }
        `;
      case "xs":
        return css`
          font-size: ${props.theme.fontSizes.mobile.s}px;
          line-height: ${props.theme.lineHeights.mobile.s}px;

          @media (min-width: ${props.theme.breakpoints.tablet}px) {
            font-size: ${props.theme.fontSizes.desktop.s}px;
            line-height: ${props.theme.lineHeights.desktop.s}px;
          }
        `;
    }
  }}

  ${(props) => withMargin(props)}
`;

const Heading = ({
  size = "xl",
  children,
  margin,
  as = "h1",
  light = false,
  semiBold = false,
  muted = false,
  grey = false,
  align = "left",
  slashBefore = false,
  slashInline = false,
}) => (
  <Container
    as={as}
    size={size}
    margin={margin}
    light={light}
    semiBold={semiBold}
    muted={muted}
    grey={grey}
    align={align}
    slashBefore={slashBefore}
    slashInline={slashInline}
  >
    {children}
  </Container>
);

Heading.propTypes = {
  size: PropTypes.oneOf(["xs", "s", "m", "ms", "l", "xl", "xxl"]),
  light: PropTypes.bool,
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "p"]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  semiBold: PropTypes.bool,
  grey: PropTypes.bool,
  muted: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center", "right"]),
  slashBefore: PropTypes.bool,
};

export default Heading;